import React from 'react'
import styled from '@emotion/styled'

const EmploymentContainer = styled.div`
  width: 100%;
  max-width: 800px;
  background: #fff;
  padding: 50px 0px 0px 0px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: calc(35% + 0px);
    bottom: 0px;
    width: 4px;
    background: #ddd;
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

const EmploymentEntry = styled.div`
  clear: both;
  text-align: left;
  position: relative;
`

const EmploymentEntryTitle = styled.div`
  float: left;
  width: 35%;
  padding-right: 30px;
  text-align: right;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 4px solid salmon;
    background-color: #fff;
    border-radius: 100%;
    top: 15%;
    right: -12px;
    z-index: 99;
  }
  h2,
  h3,
  p {
    margin: 0;
  }
  h2 {
    font-size: 120%;
  }
  h3 {
    font-size: 110%;
  }
  p {
    font-size: 90%;
  }
`

const EmploymentEntryBody = styled.div`
  margin: 0 0 1em;
  float: right;
  width: 65%;
  padding-left: 30px;
  p {
    line-height: 1.4em;
    &:first-child {
      margin-top: 0;
      font-weight: 400;
    }
  }
  ul {
    color: #aaa;
    padding-left: 0;
    list-style-type: none;
    li:before {
      content: '–';
      margin-right: 0.5em;
    }
  }
`

type Job = {
  dateStart: string
  dateFinish: string
  title: string
  company: string
  description: string
  points: Array<string>
}

const defaultDESC = 'Voluptatibus veniam ea reprehenderit atque reiciendis non laborum adipisci ipsa pariatur omnis.'
const defaultPoints = ['TODO', 'TODO', 'TODO']

const jobs: Array<Job> = [
  {
    dateStart: '2019 October',
    dateFinish: 'Present',
    title: 'Scientific Programmer',
    company: 'Department of Environment and Science Queensland Government',
    description: defaultDESC,
    points: defaultPoints
  },
  {
    dateStart: '2019 January',
    dateFinish: '2019 March',
    title: 'Research Scholar',
    company: 'National Center for Supercomputing Applications',
    description: 'Completed as part of a research project between the research computing centre at the University of Queensland and NCSA.',
    points: defaultPoints
  },
  {
    dateStart: '2018 June',
    dateFinish: '2019 October',
    title: 'Level 1 and 2 Trooper',
    company: 'Rogue Two',
    description: defaultDESC,
    points: defaultPoints
  },
  {
    dateStart: '2018 January',
    dateFinish: '2018 June',
    title: 'Tools Engineer',
    company: 'WorkingMouse',
    description: defaultDESC,
    points: defaultPoints
  },
  {
    dateStart: 'February 2016',
    dateFinish: 'January 2018',
    title: 'Software Developer',
    company: 'Smarter Business Software',
    description:
      'Create personalized systems to remove the inefficiencies of clients business which allows them to spend more time growing their business.',
    points: defaultPoints
  },
  {
    dateStart: 'February 2016',
    dateFinish: 'January 2018',
    title: 'Business Efficiency Consultant',
    company: 'Smarter Business Processes',
    description:
      'I identify areas of businesses where there is time wasted in processes that are either outdated or overly tedious and find ways for businesses to reclaim that time that is currently being unproductive.',
    points: defaultPoints
  },
  {
    dateStart: 'February 2014',
    dateFinish: 'November 2015',
    title: 'Student Technology Support Team Leader',
    company: "St Joseph's College, Gregory Terrace",
    description:
      'Created a system of student run and managed technology support, this included training younger students in customer service and increasing their knowledge of computing systems. This model was adopted by other school using our system as the catalyst',
    points: defaultPoints
  }
]

const EmploymentTimeline = () => {
  return (
    <EmploymentContainer>
      {jobs.map(j => {
        return (
          <EmploymentEntry>
            <EmploymentEntryTitle>
              <h2>{j.title}</h2>
              <h3>
                {j.dateStart} – {j.dateFinish}
              </h3>
              <p>{j.company}</p>
            </EmploymentEntryTitle>
            <EmploymentEntryBody>
              <p>{j.description}</p>
              <ul>
                {j.points.map(p => {
                  return <li>{p}</li>
                })}
              </ul>
            </EmploymentEntryBody>
          </EmploymentEntry>
        )
      })}
    </EmploymentContainer>
  )
}

export default EmploymentTimeline
