import * as React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Page from '../components/Page'
import SEO from '../components/seo'
import Layout from '../layouts/layout'
import EmploymentTimeline from '../components/EmploymentTimeline'

const Intro = styled.div``

const Freelance = styled.div``

const Education = styled.div``

const Skills = styled.div``

const Interests = styled.div``

const ResumePage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Resume" />
      <Page>
        <>
          <h1>Resume page</h1>
          <Intro></Intro>
          <EmploymentTimeline />
          <Freelance></Freelance>
          <Education></Education>
          <Skills></Skills>
          <Interests></Interests>
        </>
      </Page>
    </Layout>
  )
}

export default ResumePage
